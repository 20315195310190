if(!landing) {
    var landing = {};
}
if(!landing.last) {
    landing.last = {};
}

landing.last.LastController = function($stateParams, $state) {
    var vm = this;
    vm.back = back;
    vm.person = {};

    init();

    function init() {
        vm.person = $stateParams.person;
    }

    function back() {
        $state.go('first', {cust: $stateParams.cust, guid: $stateParams.guid, person: vm.person});
    }

};

module.exports = landing.last.LastController;
