if (!landing) {
    var landing = {};
}
if (!landing.first) {
    landing.first = {};
}

landing.first.FirstController = function ($state, $stateParams, $http, $q, firstService) {
    var vm = this;
    vm.person = {};
    vm.errorMessage = "";
    vm.update = update;

    init();

    function init() {
        vm.person = $stateParams.person;
    }

    function update(valid) {
        console.log("post2")

        if (valid) {
            firstService.update($stateParams.cust, vm.person).then(function (result) {
                $state.go('last', {cust: $stateParams.cust, guid: $stateParams.guid, person: vm.person})
            }, function (error) {
                if(angular.equals(error.data.code, "LA005")){
                    vm.errorMessage = "E-postadressen du angav är upptagen, välj en ny."
                } else if(angular.equals(error.data.code, "LA002")){
                    vm.errorMessage = "Ogiltig e-postadress"
                }
            });
        } else {
            vm.errorMessage = "Ogiltig e-postadress";
        }
    }
};

module.exports = landing.first.FirstController;

