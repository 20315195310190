if(!landing) {
    var landing = {};
}
if(!landing.last) {
    landing.last = {};
}

landing.last.LastController = require("./last.controller.js");

angular.module('landing.last',[]);

module.exports = landing.last;