'use strict';

require("angular");
require("angular-animate");
require("angular-aria");
require("angular-ui-router");
require("angular-material");
require("angular-messages");

var landing = {};

landing.header = require("./landing/header/header.module");
landing.start = require("./landing/start/start.module.js");
landing.first = require("./landing/first/first.module.js");
landing.last = require("./landing/last/last.module.js");

angular.module("landing", ['ui.router', 'ngMaterial', 'landing.header', 'landing.start', 'landing.first', 'landing.last'])
    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function ($stateProvider, $urlRouterProvider, $locationProvider) {
        // $urlRouterProvider.otherwise("/");
        $stateProvider
            .state('start', {
                url: '/:cust/:guid',
                templateUrl: 'js/landing/start/start.html',
                controller: landing.start.StartController,
                controllerAs: 'vm'
            })
            .state('first', {
                url: '/registration/:cust/:guid',
                templateUrl: 'js/landing/first/first.html',
                params: {
                    person: {}
                },
                controller: landing.first.FirstController,
                controllerAs: 'vm'
            })
            .state('last', {
                url: '/success/:cust/:guid',
                templateUrl: 'js/landing/last/last.html',
                params: {
                    person: {}
                },
                controller: landing.last.LastController,
                controllerAs: 'vm'
            })
            .state('fail', {
                url:'/unsuccessful',
                templateUrl: 'js/landing/unsuccessful/unsuccessful.html'
            });
        //
        // $locationProvider.html5Mode(true);
        $locationProvider.hashPrefix('email');
    }]);



